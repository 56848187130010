exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-topic-contentful-topic-topic-js": () => import("./../../../src/pages/blog/topic/{ContentfulTopic.topic}.js" /* webpackChunkName: "component---src-pages-blog-topic-contentful-topic-topic-js" */),
  "component---src-pages-blog-wp-post-slug-js": () => import("./../../../src/pages/blog/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-slug-js" */),
  "component---src-pages-campus-alerts-js": () => import("./../../../src/pages/campus-alerts.js" /* webpackChunkName: "component---src-pages-campus-alerts-js" */),
  "component---src-pages-course-contentful-course-name-js": () => import("./../../../src/pages/course/{ContentfulCourse.name}.js" /* webpackChunkName: "component---src-pages-course-contentful-course-name-js" */),
  "component---src-pages-discovery-js": () => import("./../../../src/pages/discovery.js" /* webpackChunkName: "component---src-pages-discovery-js" */),
  "component---src-pages-essay-submission-confirmation-js": () => import("./../../../src/pages/essay-submission-confirmation.js" /* webpackChunkName: "component---src-pages-essay-submission-confirmation-js" */),
  "component---src-pages-essay-submission-js": () => import("./../../../src/pages/essay-submission.js" /* webpackChunkName: "component---src-pages-essay-submission-js" */),
  "component---src-pages-faculty-contentful-staff-member-name-js": () => import("./../../../src/pages/faculty/{ContentfulStaffMember.name}.js" /* webpackChunkName: "component---src-pages-faculty-contentful-staff-member-name-js" */),
  "component---src-pages-give-js": () => import("./../../../src/pages/give.js" /* webpackChunkName: "component---src-pages-give-js" */),
  "component---src-pages-housing-js": () => import("./../../../src/pages/housing.js" /* webpackChunkName: "component---src-pages-housing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-students-js": () => import("./../../../src/pages/international-students.js" /* webpackChunkName: "component---src-pages-international-students-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-online-js": () => import("./../../../src/pages/online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-story-contentful-story-name-js": () => import("./../../../src/pages/story/{ContentfulStory.name}.js" /* webpackChunkName: "component---src-pages-story-contentful-story-name-js" */),
  "component---src-pages-student-life-js": () => import("./../../../src/pages/student-life.js" /* webpackChunkName: "component---src-pages-student-life-js" */)
}

